<template>
  <q-tr
      :props="data"
      :class="color"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="image"
        :props="data"
    >
      <div v-if="data.row._embedded.productOffer">
        <img
            :src="data.row._embedded?.productOffer.image || appOptions.fallbackImage"
            style="height: 80px; width: 80px; object-fit: contain;"
            @error="onImageLoadFailure"
        >

        <q-btn
            :label="$t(data.row._embedded?.productOffer.type)"
            color="light-blue-9"
            icon-right="visibility"
            :disable="true"
            size="sm"
            style="min-width: 120px;"
            class="q-my-xs block full-width text-capitalize"
            no-caps
            @click="handleShowInfo"
        />
      </div>
      <div v-else>
        {{'-'}}
      </div>

    </q-td>

    <q-td
        key="name"
        :props="data"
        style="max-width: 250px; word-wrap: break-word; white-space: normal !important;"
    >
      <div v-if="data.row._embedded.productOffer">
        <div class="text-subtitle2 q-my-sm">
          {{ name }}
        </div>

        <span
            v-if="!data.row._embedded.productOffer.items || data.row._embedded.productOffer.items.length === 0 || !data.row._embedded.productOffer.items[0]">
        <strong>-</strong>
      </span>

        <div v-else>
          <items-table
              :items="data.row._embedded.productOffer.items"
              :offer="data.row._embedded.productOffer"
              show-details
          />
        </div>
      </div>

      <div v-else>
        {{'-'}}
      </div>

    </q-td>

    <q-td
        key="sellerSku"
        :props="data"
        style="max-width: 250px; word-wrap: break-word; white-space: normal !important;"
    >
      <div class="text-subtitle2 q-my-sm">
        {{ data.row.sellerSku }}
      </div>

      <div class="text-subtitle2 q-my-sm">
        {{'Id: '+ data.row.id }}
      </div>
    </q-td>

    <td
        key="fulfillmentSku"
        :props="data"
        style="max-width: 250px; word-wrap: break-word; white-space: normal !important;"
    >
      <div class="text-subtitle2 q-my-sm">
        {{ data.row.fulfillmentNetworkSku }}
      </div>
    </td>

    <q-td
        key="count"
        :props="data"
        style="min-width: 120px;"
    >
      <q-input
          standout="bg-teal text-white"
          :model-value="data.row.quantity"
          :disable="true"
          :label="$t('Quantity')"
          @update:model-value="handleNumberUpdate('count', $event)"
      />
    </q-td>

    <q-td
        key="total"
        :props="data"
    >
      {{ total }}
    </q-td>


    <q-td
        key="after"
        ref="eavModal"
    >
      <div>
        <q-btn
            color="black"
            label="EAV"
            :disable="true"
            ref="eavModal"
            @click="onClick(data , this.$refs.eavModal)"
        />
      </div>
      <br/>
      <div>
        <q-btn
            color="negative"
            text-color="white"
            icon="delete"
            :disable="true"
            no-caps
            unelevated
            @click="deleteRow"
        />
      </div>
    </q-td>
    <eav-modal ref="eavModal">
      <form-builder :schema="schema2"/>
    </eav-modal>
  </q-tr>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import ItemsTable from '../items-table/ItemsTable.vue'
import EavModal from '../../components/modals/EavModal.vue'
import { InstructionsService } from '@/apps/app/services/instructions.service'

export default {
  name: 'ShippingPlanItemsRow',
  emits: ['open', 'change', 'delete', 'open-reserve'],
  components: {
    ItemsTable,
    EavModal
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          warehouses: [],
          reserve: null,
          warehouse: null,
          minPrice: 0,
          minAmount: 0
        }
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      stateColor: {
        new: 'grey-4',
        picking: 'teal-12',
        cancelled: 'danger',
        confirmed: 'teal'
      },
      quantityReserved: 0,
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    name () {
      const name = this.data.row._embedded.productOffer.name

      if (this.data.row.id) {
        return `${name} (${this.data.row.id})`
      }

      return name
    },
    schema2 () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.data.row.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
              }
            ]
          }
        ]
      }
    },
    reserve () {

      if (!this.options.reserve) {
        this.resetQuantityReserved()
        return [{
          items: 0,
          id: this.$t('No reserve')
        }]
      }

      this.checkQuantity()

      return this.options.reserve
    },
    color () {
      if (this.options.noColor) {
        return ''
      }

      if (this.reserve.items >= this.data.row.count || this.quantityReserved >= this.data.row.count) {
        return ''
      }

      return ''
    },
    warehouse () {
      if (this.options.warehouse) {
        return this.options.warehouse
      }

      if (this.options.reserve && this.options.reserve.warehouse) {
        const base = {
          id: this.options.reserve.warehouse,
          name: ''
        }

        if (!this.options.warehouses) {
          return base
        }

        return this.options.warehouses.find(({ id }) => `${id}` === `${base.id}`) || base
      }

      return {
        id: '',
        name: ''
      }
    }
  },
  beforeMount () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    this.isLoading = true

  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleReserveClick (reserve) {
      this.$emit('open-reserve', reserve)
    },
    onImageLoadFailure (event) {
      event.target.src = this.appOptions.fallbackImage
    },
    checkQuantity () {
      if (!this.quantityReserved) {
        this.options.reserve.forEach(e => this.quantityReserved += e.items)
      }
    },
    handleShowInfo () {
      this.$emit('open', this.data.row._embedded.productOffer)
    },
    handleNumberUpdate (key, value) {
      if (key === 'price' && typeof this.options.minPrice === 'number' && value < this.options.minPrice) {
        this.addErrorNotification(`Minimum price is ${this.options.minPrice}!`)
        return
      }

      if (key === 'count' && typeof this.options.minAmount === 'number' && value < this.options.minAmount) {
        this.addErrorNotification(`Minimum count is ${this.options.minAmount}!`)
        return
      }

      this.$emit('change', { product: this.data.row, update: { [key]: value } })
    },
    deleteRow () {
      this.$emit('delete', this.data.row._embedded.productOffer.id)
    },
    onClick: (item, ref) => {
      const options = {
        entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer'
      }

      ref.open(item.row.eav, options)
    },
    resetQuantityReserved () {
      return this.quantityReserved = 0
    }
  }
}
</script>
