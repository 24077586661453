<template>
  <div>
    <form-builder ref="formBuilder" :schema="schema" />

    <div class="q-py-md q-mt-md q-px-xs border-top">
      <products-search
          :products="products"
          :options="options"
          :disabled="true"
          :placeholder="$t('Search products in the shippingPlan form')"
          no-padding
          @on-search="handleSearch"
          @submit="handleSubmit"
      >
        <q-btn
            color="dark"
            size="sm"
            class="q-mr-sm"
            :label="$t('Filter')"
            no-caps
            unelevated
            @click="toggleFilters"
        />

        <q-btn-dropdown
            v-if="shippingPlan && shippingPlan.type === 'return'"
            :class="isMobile ? 'col-12 q-mt-md' : ''"
            color="dark"
            text-color="white"
            class="q-ml-sm"
            no-caps
            unelevated
            :label="$t('New product')"
            :disable="disabled"
        >
          <q-list>
            <q-item clickable v-close-popup @click="create('simple')">
              <q-item-section>
                <q-item-label>
                  {{ $t("Simple type") }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="create('grouped')">
              <q-item-section>
                <q-item-label>
                  {{ $t("Grouped") }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="create('bundle')">
              <q-item-section>
                <q-item-label>
                  {{ $t("Bundle") }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </products-search>

      <filter-collapse
          :is-open="isOpenFilter"
          :options="{
          defaultFilter: filters,
          fields: activatedFields,
          style: {
            noGroups: true,
          },
        }"
          @submit="handleFiltersSubmit"
          @close="toggleFilters"
      />

      <q-table
          class="sticky-header-table q-mt-md"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :filter="filter"
          :columns="columns"
          v-model:pagination="pagination"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          :loading="isLoading"
          virtual-scroll
          binary-state-sort
          @request="onRequest"
      >
        <template v-slot:body="props">
          <shipping-plan-items-row
              :data="props"
              :disabled="disabled"
              :options="{
              warehouse: warehouse,
              minPrice: 0,
            }"
              @open="handleShowInfo"
              @delete="deleteRow"
          />
        </template>
      </q-table>


      <offer-modal ref="offerModal" @submit="handleOfferSubmit" />

      <items-info-modal ref="itemsInfo" />
    </div>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapMutations} from 'vuex'

// Components
import OfferModal from '../modals/OfferModal.vue'
import ProductsSearch from '../products/ProductsSearch.vue'
import ShippingPlanItemsRow from '../amazon/ShippingPlanItemsRow.vue'
import ItemsInfoModal from '../modals/ItemsInfoModal.vue'
import FilterCollapse from '../filters/FilterCollapse.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'
import _ from 'lodash'

export default {
  name: 'ShippingPlanContent',
  emits: ['shop-change', 'warehouse-change'],
  components: {
    OfferModal,
    ProductsSearch,
    ShippingPlanItemsRow,
    ItemsInfoModal,
    FilterCollapse
  },
  props: {
    isOrderLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shippingPlan: {
      type: Object,
      default () {
        return null
      }
    },
    warehouse: {
      type: Object,
      default () {
        return null
      }
    },
    shop: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      filters: [],
      activatedFields: [
        'id',
        'price',
        'created.from',
        'created.to'
      ],
      isOpenFilter: false,
      orderProducts: [],
      isLoading: false,
      changes: {},
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      search: '',
      paymentsStates: [
        { id: 'paid', name: this.$t('Paid') },
        { id: 'not_paid', name: this.$t('Not paid') }
      ],
      isDisabledWarehouse: false,
      isDisabledShop: false,
      fullShop: {},
      items: []
    }
  },
  computed: {
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Product Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Seller Sku'),
          name: 'sellerSku',
          align: 'left'
        },
        {
          label: this.$t('Fulfillment Network Sku'),
          name: 'fulfillmentSku',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'count',
          align: 'left'
        },
        {
          name: 'after',
          align: 'left'
        }
      ]
    },
    isMobile () {
      return window.innerWidth < 700
    },
    products () {
      return this.orderProducts.map(x => {
        return {
          ...x,
          raw: x
        }
      })
    },
    options () {
      return {
        onlyOneShop: true,
        allWarehouses: true,
        disabledWarehouse: this.shippingPlan && this.shippingPlan.id,
        filter: this.shippingPlan && this.shippingPlan.type === 'bundle'
            ? [{ type: 'eq', field: 'type', value: 'bundle' }]
            : [],
        defaultValues: {
          shop: this.shippingPlan && this.shippingPlan.shop,
          warehouse: this.warehouse
        }
      }
    },
    totalPrice () {
      return this.orderProducts.reduce((acc, val) => {
        return acc + val.price * val.count
      }, 0).toFixed(2)
    },
    schema () {

      return {
        isLoading: this.isOrderLoading,
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                ref: 'shop',
                type: 'multiselect',
                label: this.$t('Store'),
                field: 'shop',
                value: this.fullShop,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                disabled: true ,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'in', values: ['active','blocked'] }
                    ]
                  }

                  if (
                      this.warehouse &&
                      this.warehouse.owner
                  ) {
                    query.filter.push({ type: 'eq', field: 'owner', value: this.warehouse._embedded.owner.id })
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: (shop) => {

                  this.$emit('update-source', null)
                  this.$emit('shop-change', shop)
                  this.setHasOrderChange(true)
                }
              },
              {
                ref: 'warehouse',
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.shippingPlan._embedded?.warehouse || {},
                disabled: true,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'eq', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  if (!warehouse || !this.shippingPlan._embedded.warehouse) {
                    this.resetShop(warehouse)
                  } else if (
                      warehouse &&
                      this.warehouse &&
                      this.warehouse._embedded &&
                      this.warehouse._embedded.owner &&
                      this.warehouse._embedded.owner.id !== warehouse._embedded.owner.id
                  ) {
                    this.resetShop(warehouse)
                  }

                  this.$emit('warehouse-change', warehouse)
                }
              },
              {
                ref: 'source',
                type: 'multiselect',
                label: this.$t('Source'),
                field: 'source',
                value: this.shippingPlan && this.shippingPlan?._embedded?.source,
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                required: true,
                disabled: true,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id}) / ${row.handler} / ${row._embedded.owner.name}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  if(this.fullShop) {
                    const query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'in', field: 'state', values: ['active', 'blocked'] },
                        { type: 'eq', field: 'owner', value: this.fullShop._embedded.owner.id }
                      ]
                    }

                    return this.$service.iSource.getAll(query)
                  } else {
                    const query = {
                      per_page: 25,
                      page,
                      search,
                      filter: [
                        { type: 'in', field: 'state', values: ['active', 'blocked'] },
                        { type: 'eq', field: 'owner', value: this.shop._embedded.owner.id }
                      ]
                    }

                    return this.$service.iSource.getAll(query)
                  }


                },
                onChange: (source) => {
                  this.$emit('update-source', source)
                }
              },
              {
                type: 'select',
                label: this.$t('Payment status'),
                value: this.shippingPlan && this.shippingPlan.paymentState,
                disabled: true,
                field: 'paymentState',
                disabledClean: true,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.name
                      : row
                },
                wrapperStyleClasses: 'col-12 col-md-3 q-pa-xs',
                options: this.paymentsStates,
                onChange: (paymentState) => {
                  this.$emit('update-shippingPlan', { paymentState: paymentState.id })
                  this.setHasOrderChange(true)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.shippingPlan && this.shippingPlan.comment,
                max: 255,
                hint: this.$t('Max 255 characters.'),
                label: this.$t('Comment'),
                disabled: true,
                wrapperStyleClasses: 'col q-pa-xs',
                onInput: comment => {
                  this.$emit('update-shippingPlan', { comment })
                  this.setHasOrderChange(true)
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    orderProducts: {
      handler (value) {
        this.$emit('products-change', value)
      },
      deep: true
    },
    isOrderLoading () {
      if (this.shippingPlan && this.shippingPlan.id) {
        const pagination = {
          rowsPerPage: 25,
          page: 1
        }

        this.onRequest({ pagination })
        this.loadData()
      }
    }
  },
  mounted () {
    if (this.shippingPlan && this.shippingPlan.id) {
      const pagination = {
        rowsPerPage: 25,
        page: 1
      }

      this.onRequest({ pagination })
    }
  },
  updated () {
    if(this.shippingPlan._embedded?.warehouse) {
      const owner = this.shippingPlan._embedded.warehouse._embedded.owner._links.self.href.split('/').pop()

      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'in', field: 'state', values: ['active', 'blocked'] }
        ]
      }


      query.filter.push({ type: 'eq', field: 'owner', value: owner })


      return this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('shop-change', items[0])
              this.isDisabledShop = true
            }

            this.fullShop = items[0]
            return
          })
    } else {
      this.fullShop = this.shop
    }
  },
  methods: {
    ...mapActions([
      'loadShippingPlanItems',
    ]),
    ...mapMutations([
      'addErrorNotification',
      'setHasShippingPlanChange',
      'setShippingPlan'
    ]),
    toggleFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    loadData () {
      if (this.shippingPlan && !this.shippingPlan.id) {
        this.loadDefaultValues()
      }
    },
    resetShop (warehouse) {
      this.$emit('shop-change', null)
      this.$refs.formBuilder.$refs.form[0].$refs.shop[0].$refs.shop.reset()

      if (warehouse) {
        this.loadShop(warehouse)
      }
    },
    loadShop (warehouse) {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'in', field: 'state', values: ['active', 'blocked' ]}
        ]
      }

      if (warehouse && warehouse._embedded && warehouse._embedded.owner) {
        query.filter.push({ type: 'eq', field: 'owner', value: warehouse._embedded.owner.id })
      }

      return this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('shop-change', items[0])
              this.isDisabledShop = true
            }

            return this.shippingPlan._embedded.shop
          })
    },
    loadDefaultValues () {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      this.$service.warehouse.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('warehouse-change', items[0])
              this.isDisabledWarehouse = true
            }
          })
      query.filter.push({ type: 'eq', field: 'state', value: 'blocked' })
      this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.$emit('shop-change', items[0])
              this.isDisabledShop = true
            }
          })
    },
    reset () {
      this.orderProducts = []
      this.changes = {}
      this.search = ''

      const pagination = {
        per_page: 25,
        page: 1
      }

      return this.onRequest({ pagination })
    },
    create (type) {
      this.$refs.offerModal.open(undefined, { type, shop: this.shippingPlan._embedded.shop })
    },
    handleSearch (search) {
      this.search = search

      if (this.shippingPlan && this.shippingPlan.id) {
        return this.onRequest({ pagination: { per_page: 25, page: 1 } })
      }

      return Promise.resolve(this.items)
    },
    getAll () {
      return this.orderProducts.filter(x => x.count > 0)
    },
    getChanges () {
      if (!this.shippingPlan || !this.shippingPlan.id) {
        return this.orderProducts.filter(x => x.count > 0)
      }

      return Object.values({ ...this.changes, [this.pagination.page]: this.orderProducts.filter(x => !x.id || x.hasChange) }).reduce((acc, arr) => {
        return [...acc, ...arr]
      }, [])
    },
    handleSubmit (data) {
      this.$emit('shop-change', data.shop)
      this.$emit('warehouse-change', data.warehouse)
      const products = data.products.reduce((acc, p) => {
        acc.push(this.convertProduct(p))
        return acc
      }, [])

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    convertProduct (product) {
      if (product.raw) {
        return {
          ...product.raw,
          hasChange: true,
          count: Number(product.count)
        }
      }

      return {
        ...product,
        hasChange: true
      }
    },
    handleOfferSubmit (offer) {
      let hasProduct = false

      const products = this.orderProducts.map(product => {
        if (product._embedded && product._embedded.productOffer && `${product._embedded.productOffer.id}` === `${offer.id}`) {
          hasProduct = true
          product._embedded.productOffer = offer
        }

        return product
      })

      if (!hasProduct) {
        if (!this.shippingPlan._embedded.shop) {
          this.$emit('shop-change', offer._embedded.shop)
        }

        products.push(this.convertProduct({ ...offer, count: 1, raw: undefined }))
      }

      this.setHasOrderChange(true)
      this.orderProducts = products
    },
    handleShowInfo (offer) {
      this.$refs.offerModal.open(offer)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      query.filter.push({ field: 'shippingPlan', type: 'eq', value: this.shippingPlan.id })
      this.isLoading = true
      return this.loadShippingPlanItems(query)
          .then(({ items }) => {
            this.items = items
            return items
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    mergeChanges (changes, items) {
      const updatedItems = [...items]

      changes.forEach(x => {
        if (x.id) {
          const index = updatedItems.findIndex(i => i.id === x.id)

          if (index > -1) {
            updatedItems[index] = x
          } else {
            updatedItems.push(x)
          }
        } else {
          updatedItems.push(x)
        }
      })

      return updatedItems
    },
    deleteRow (offerId) {
      const products = this.orderProducts.map(val => {
        if (val.id === offerId) {
          return { ...val, count: 0, hasChange: true }
        }

        return val
      })

      this.setHasOrderChange(true)
      this.orderProducts = products
    }
  }
}
</script>
